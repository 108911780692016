@import "./theme.less";
@import "./atmx/default.less";

@header-height :48px+8*2;
@sidebar-width: 200px+8*12;

#editor-container {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  .editor-header {
    .editor-header {
      padding: 10px 20px;
      margin: 10px;
      overflow: initial;
    }
  }

  .editor-main {
    display: flex;

    .editor-content {
      padding: 24px;
      min-height: 30em;
      margin: 10px;
      overflow-y: auto;

      .edit-area {
        .editor-content-style();
      }
    }

    .editor-sidebar {
      flex: 0 0 @sidebar-width  !important;
      max-width: 50vw !important;
      min-width: 10vw !important;
      margin: 10px;
      overflow: auto;

      .propertie-panel {
        height: 100%;

        main {
          flex-grow: 1;
          padding: 24px;
          overflow-y: hidden;
        }
      }
    }
  }
}