.editor-content-style {

  h1,
  #page-title {
    color: #901;
    padding: 0 0 0.25em;
    margin: 0 0 0.6em;
    font-weight: normal;
  }

  h1 {
    margin-top: 0.7em;
    padding: 0;
    font-weight: bold;
    font-size: 190%;
  }

  h2 {
    font-weight: normal;
    font-size: 150%;
  }

  h3 {
    font-weight: bold;
    font-size: 130%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Trebuchet MS", Trebuchet, Verdana, Arial, Helvetica, sans-serif;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5em 0 0.4em;
    padding: 0;
    letter-spacing: 1px;
  }

  p {
    margin: 1em 0;
  }

  li,
  p {
    line-height: 141%;
  }

  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .deleted {
    text-decoration: line-through;
  }

  .underline.deleted {
    text-decoration: underline line-through !important;
  }

  blockquote {
    border: 1px dashed #999;
    margin: 12.8px 40px;
    padding: 0 1em;
    background-color: #f4f4f4;
  }

  hr {
    margin: 1em 2em;
    padding: 0;
    height: 1px;
    border: none;
    color: #555545;
    color: #DDD;
    background-color: #AAA;
    background-color: #555545;
  }
}